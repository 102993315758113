import { FE_SimpleBot } from "../models/integration";
import { ScalarClientApiService } from "../services/scalar/scalar-client-api.service";
import { ToasterService } from "angular2-toaster";
import { TranslateService } from "@ngx-translate/core";
import { IntegrationsApiService } from "../services/integrations/integrations-api.service";

export class SimpleBotController {
    constructor(
        public readonly bot: FE_SimpleBot,
        private scalar: ScalarClientApiService,
        private integrationsApi: IntegrationsApiService,
        private toaster: ToasterService,
        private translate: TranslateService,
    ) {
    }

    public async addBotToRoom(roomId: string, key: string) {
        if (this.bot._inRoom) {
            return;
        }
        const promise = this.scalar.addBotToRoom(roomId, key);
        this.bot._inRoom = !this.bot._inRoom;
        this.bot._isUpdating = true;
        try {
            await promise;
            this.bot._isUpdating = false;
            this.translate.get('Add bot successfully').subscribe((translated: string) => {
                this.toaster.pop("success", translated);
            });
        } catch (err: any) {
            this.bot._inRoom = !this.bot._inRoom; // revert the status change
            this.bot._isUpdating = false;
            console.error(err);
            this.translate.get('Cannot invite bot to room').subscribe((translated: string) => {
                this.toaster.pop("error", `${translated}`);
            });
        }
    }
}

<div id="jitsiContainer">
</div>

<div class="join-conference-wrapper">
    <div class="join-conference-boat">
        <div *ngIf="!isJoined" class="join-conference-prompt">
            <h3>Jitsi Video Conference</h3>
            <button type="button" (click)="joinConference()" class="btn btn-primary btn-large">
                {{'Join Conference' | translate}}
            </button><br>
            <label>
                {{'Start camera:' | translate}}
                <input type="checkbox" name="toggleVideo" [(ngModel)]="toggleVideo" />
            </label>
        </div>
    </div>
</div>

<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'New video' | translate}}"
        editTitle="{{'Videos' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <my-field
                label="{{'Video URL' | translate}}"
                placeholder="https://www.youtube.com/watch?v=jr2mXSKq3B4"
                [(value)]="widget.dimension.newData.videoUrl"
                [disabled]="isUpdating"
        ></my-field>
    </ng-template>
</my-widget-config>

import {animate, state, style, transition, trigger,} from "@angular/animations";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import { HttpErrorResponse } from "@angular/common/http";
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs/Subscription";
import {FE_Sticker, FE_UserStickerPack,} from "../../shared/models/integration";
import {WIDGET_STICKER_PICKER} from "../../shared/models/widget";
import {StickerApiService} from "../../shared/services/integrations/sticker-api.service";
import {MediaService} from "../../shared/services/media.service";
import {ScalarServerApiService} from "../../shared/services/scalar/scalar-server-api.service";
import {ScalarWidgetApi} from "../../shared/services/scalar/scalar-widget.api";
import {SessionStorage} from "../../shared/SessionStorage";
import {CapableWidget, WIDGET_API_VERSION_OPENID} from "../capable-widget";
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {LogService} from "../../shared/services/log.service";

@Component({
    selector: "my-generic-widget-wrapper",
    templateUrl: "sticker-picker.component.html",
    styleUrls: ["sticker-picker.component.scss"],
    animations: [
        trigger("hideList", [
            state("hidden", style({ opacity: 0, transform: "translateY(100%)" })),
            state("visible", style({ opacity: 1, transform: "translateY(0)" })),
            transition("* => *", animate("200ms ease-in")),
        ]),
    ],
})
export class StickerPickerWidgetWrapperComponent
    extends CapableWidget
    implements OnInit, OnDestroy {
    public isLoading = true;
    public authError = false;
    public packs: any[];
    public isSearchSticker = false;
    public inputSearchSticker = '';
    public packsOfSearching: FE_UserStickerPack[];
    public isDesktop = false;
    private stickerWidgetApiSubscription: Subscription;
    public indexScrolled = 0;
    public currentSection = 'pack-0';

    @ViewChild('stickerPackMenu') virtualScrollStickerPackMenu: CdkVirtualScrollViewport;
    @ViewChild('stickerPackContent') virtualScrollStickerPackContent: CdkVirtualScrollViewport;
    @ViewChild('stickerPack') stickerPack: CdkVirtualScrollViewport;

    selectedIndex: number = 0;
    SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

    constructor(
        activatedRoute: ActivatedRoute,
        private media: MediaService,
        private scalarApi: ScalarServerApiService,
        private stickerApi: StickerApiService,
        private changeDetector: ChangeDetectorRef,
        private dbService: NgxIndexedDBService,
        private logService: LogService
    ) {
        super();
        this.supportsStickers = true;

        const params: any = activatedRoute.snapshot.queryParams;
        const userId = params.user_id;
        if (userId) {
            SessionStorage.userId = userId;
        }

        this.isDesktop = params.deviceType && params.deviceType === 'desktop';

        let token = params.scalar_token;
        if (!token) token = localStorage.getItem("dim-scalar-token");
        else localStorage.setItem("dim-scalar-token", token);

        if (!params.widgetId) {
            console.error("No widgetId query parameter");
            this.authError = true;
            this.isLoading = false;
        } else {
            ScalarWidgetApi.widgetId = params.widgetId;
        }

        if (!this.authError) {
            SessionStorage.scalarToken = token;
            this.authError = !token;
            this.isLoading = !this.authError;
        }
    }

    public ngOnInit() {
        super.ngOnInit();
        this.stickerWidgetApiSubscription =
        ScalarWidgetApi.requestReceived.subscribe((request) => {
            if (request.action === "visibility") {
                if ((<any>request).visible) this.loadStickers();
                ScalarWidgetApi.replyAcknowledge(request);
            } else if (request.action === "clear_local_storage") {
                localStorage.clear()
            }
        });
        this.loadStickers();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        if (this.stickerWidgetApiSubscription)
            this.stickerWidgetApiSubscription.unsubscribe();
    }

    protected onSupportedVersionsFound(isReload = true): void {
        super.onSupportedVersionsFound();

        if (
            this.authError &&
      this.doesSupportAtLeastVersion(WIDGET_API_VERSION_OPENID)
        ) {
            this.isLoading = true;
            this.changeDetector.detectChanges();

            this.getOpenIdInfo().then(async (response) => {
                if (response.blocked) {
                    this.isLoading = false;
                    this.authError = true;
                    isReload && this.onSupportedVersionsFound(false);
                    this.changeDetector.detectChanges();
                    return;
                }

                try {
                    const registerResponse = await this.scalarApi.register(
                        response.openId
                    );
                    localStorage.setItem(
                        "dim-scalar-token",
                        registerResponse.scalar_token
                    );
                    SessionStorage.scalarToken = registerResponse.scalar_token;
                    this.authError = !SessionStorage.scalarToken;
                    this.isLoading = false;
                    this.loadStickers();
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                    this.authError = true;
                }

                this.changeDetector.detectChanges();
            });
        }
    }

    public getThumbnailUrl(
        mxc: string,
        width: number,
        height: number,
        method: "crop" | "scale" = "scale"
    ): string {
        return this.media.getThumbnailUrl(mxc, width, height, method, true);
    }

    private retryLoadStickers() {
        this.isLoading = true;
        this.changeDetector.detectChanges();
        this.getOpenIdInfo().then(async (response) => {
            if (response.blocked) {
                this.isLoading = false;
                this.authError = true;
                this.changeDetector.detectChanges();
                return;
            }

            try {
                const registerResponse = await this.scalarApi.register(
                    response.openId
                );
                localStorage.setItem(
                    "dim-scalar-token",
                    registerResponse.scalar_token
                );
                SessionStorage.scalarToken = registerResponse.scalar_token;
                this.authError = !SessionStorage.scalarToken;
                this.isLoading = false;
                this.loadStickers();
            } catch (e) {
                this.isLoading = false;
                this.authError = true;
            }

            this.changeDetector.detectChanges();
        });
    }

    private async loadStickers() {
        this.isLoading = true;
        if (this.authError) return; // Don't bother
        if (!SessionStorage.userId) {
            try {
                const info = await this.scalarApi.getAccount();
                SessionStorage.userId = info.user_id;
                console.log(
                    "Dimension scalar_token belongs to " + SessionStorage.userId
                );
            } catch (e) {
                this.authError = true;
                this.isLoading = false;
                if (e instanceof HttpErrorResponse && e.status === 401) {
                    this.retryLoadStickers();
                }
                return;
            }
        }
        let packs = [];
        try {
            packs = await this.dbService.getAll('packs').toPromise();
        } catch (e) {
            this.isLoading = false;
            this.logService.error(e);
        }

        if (!packs.length) {
            try {
                console.log("Attempting to load available stickers...");
                packs = await this.stickerApi.getPacksByUserId(SessionStorage.userId);
            } catch (e) {
                this.authError = true;
                this.isLoading = false;
                if (e instanceof HttpErrorResponse && e.status === 401) {
                    this.retryLoadStickers();
                }
                this.logService.error(e);
                return;
            }
            this.packs = packs.filter((p: any) => p.isSelected);
            try {
                await this.dbService
                    .bulkAdd('packs', this.packs).toPromise().catch();
            } catch (e) {
                this.logService.error(e);
            }
        } else {
            this.packs = packs;
        }
        for (let i = 0; i < this.packs.length; i++) {
            let mxcPack = this.packs[i].avatarUrl;
            let updatePack = false;
            if (!this.packs[i].animationOpts?.animationData) {
                const animationOptsPack = {
                    animationData: null,
                };
                if (this.packs[i].isAnimated) {
                    animationOptsPack.animationData = await this.media.getMediaContent(mxcPack).toPromise();
                } else {
                    const thumbnailUrl = this.media.getThumbnailUrl(mxcPack, 30, 30, 'scale', false);
                    await this.getImageBase64DataFromUrl(thumbnailUrl).then(data => {
                        animationOptsPack.animationData = data;
                    });
                }
                if (animationOptsPack.animationData !== null) {
                    this.packs[i].animationOpts = animationOptsPack;
                    updatePack = true;
                }
            }
        }
        this.isLoading = false;
        this.authError = false;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.storeStickers();
    }

    public async storeStickers() {
        let stickers = [];
        try {
            stickers = await this.dbService.getAll('stickers').toPromise();
        } catch (e) {
            this.logService.error(e);
        }

        for (let i = 0; i < this.packs.length; i++) {
            for (let j = 0; j < this.packs[i].stickers.length; j++) {
                let stickerId = `${this.packs[i].id}-${this.packs[i].stickers[j].id}`;
                let searchSticker: any = stickers.find((e: any) => {
                    return e.id === stickerId
                });
                if (searchSticker) {
                    this.packs[i].stickers[j].animationOpts = searchSticker.animationOpts;
                } else {
                    let mxcSticker = this.packs[i].stickers[j].image.mxc;
                    const animationOptsSticker = {
                        animationData: null,
                    };
                    if (!this.packs[i].stickers[j].animationOpts?.animationData) {
                        if (this.packs[i].stickers[j].image.mimetype === 'image/tgs') {
                            this.media.getMediaContent(mxcSticker).subscribe(async data => {
                                animationOptsSticker.animationData = data;
                                this.packs[i].stickers[j].animationOpts = animationOptsSticker;
                                try {
                                    await this.dbService
                                        .bulkAdd('stickers', [{
                                            id: stickerId,
                                            animationOpts: animationOptsSticker,
                                        }]).toPromise();
                                    stickers.push({
                                        id: stickerId,
                                        animationOpts: animationOptsSticker,
                                    });
                                } catch (e) {
                                    this.logService.error(e);
                                }
                            });
                        } else {
                            const thumbnailUrl = this.media.getThumbnailUrl(mxcSticker, 48, 48, 'scale', false);
                            await this.getImageBase64DataFromUrl(thumbnailUrl).then(async data => {
                                animationOptsSticker.animationData = data;
                                this.packs[i].stickers[j].animationOpts = animationOptsSticker;
                                try {
                                    await this.dbService
                                        .bulkAdd('stickers', [{
                                            id: stickerId,
                                            animationOpts: animationOptsSticker,
                                        }]).toPromise();
                                    stickers.push({
                                        id: stickerId,
                                        animationOpts: animationOptsSticker,
                                    });
                                } catch (e) {
                                    this.logService.error(e);
                                }
                            });
                        }
                    }
                }
            }
        }
    }

    public scrollHorizontal(event: WheelEvent): void {
        document.getElementsByClassName("sticker-pack-list")[0].scrollLeft +=
            event.deltaY;
        event.preventDefault();
    }

    public scrollToPack(index: number) {
        this.indexScrolled = index;
        this.virtualScrollStickerPackContent.scrollToIndex(index, "smooth");
        this.stickerPack.scrollToIndex(0, "smooth");
    }

    public sendSticker(sticker: FE_Sticker, pack: FE_UserStickerPack) {
        ScalarWidgetApi.sendSticker(sticker, pack);
    }

    public openIntegrationManager() {
        ScalarWidgetApi.openIntegrationManager(
            WIDGET_STICKER_PICKER[0],
            ScalarWidgetApi.widgetId
        );
    }

    public refresh() {
        this.loadStickers();
    }

    public searchStickers(searchText){
        this.packsOfSearching = [];
        if(searchText.trim() === ''){
            return;
        }
        this.packs.filter((pack) => {
            const stickers = pack.stickers.filter(sticker => {
                return sticker.name.toUpperCase().includes(searchText.toUpperCase());
            });
            if(stickers.length){
                this.packsOfSearching.push({
                    ...pack,
                    stickers
                });
            }
        });
    }

    public closeInputSearchStickers(){
        this.isSearchSticker = !this.isSearchSticker;
        this.inputSearchSticker = '';
        this.packsOfSearching = [];
    }

    public scrolledIndexChangeStickerPackContent(index){
        this.indexScrolled = index;
        this.virtualScrollStickerPackMenu.scrollToIndex(index, "smooth");
    }

    public onSectionChange(sectionId: string) {
        this.currentSection = sectionId;
    }

    public scrollTo(section) {
        document.querySelector('#' + section).scrollIntoView();
    }

    // Action triggered when user swipes
    public swipe(event: any) {
        const action = event.type;
        console.log('All Event', event);
        console.log('action', action);

        const tabCount = (this.inputSearchSticker.trim() === '' ? this.packs.length : this.packsOfSearching.length) - 1;
        // Out of range
        if (this.selectedIndex < 0) {
            this.selectedIndex = 0;
            return;
        }
        if (this.selectedIndex > tabCount) {
            this.selectedIndex = tabCount;
            return;
        }

        if (event.isFinal) {
            console.log('Final Event', event);
            // Swipe left, next tab
            if (action === this.SWIPE_ACTION.LEFT) {
                const isLast = this.selectedIndex === tabCount;
                this.selectedIndex = isLast
                    ? this.selectedIndex
                    : this.selectedIndex + 1;
                console.log('Swipe left - INDEX: ' + this.selectedIndex);
            }

            // Swipe right, previous tab
            if (action === this.SWIPE_ACTION.RIGHT) {
                const isFirst = this.selectedIndex === 0; /* starter point as 0 */
                this.selectedIndex = isFirst
                    ? this.selectedIndex
                    : this.selectedIndex - 1;
                console.log('Swipe right - INDEX: ' + this.selectedIndex);
            }
        }
    }

    private async getImageBase64DataFromUrl(imageUrl) {
        const res = await fetch(imageUrl);
        const blob = await res.blob();

        return new Promise((resolve, reject) => {
            const reader  = new FileReader();
            reader.addEventListener("load", function () {
                resolve(reader.result);
            }, false);

            reader.onerror = () => {
                return reject(this);
            };
            reader.readAsDataURL(blob);
        })
    }
}

<div *ngIf="isLoading">
    <my-spinner></my-spinner>
</div>
<div *ngIf="!isLoading">
    <my-ibox boxTitle="IRC Bridge Configurations">
        <div class="my-ibox-content">
            <p>
                <a href="https://github.com/matrix-org/matrix-appservice-irc" target="_blank">{{'matrix-appservice-irc' | translate}}</a>
                {{'is an IRC bridge that supports multiple IRC networks. Dimension is capable of using multiple IRC bridges to better distribute the load across multiple networks in large deployments.' | translate}}
            </p>

            <table class="table table-striped table-condensed table-bordered">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>{{'Enabled Networks' | translate}}</th>
                    <th class="text-center" style="width: 120px;">{{'Actions' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="!configurations || configurations.length === 0">
                    <td colspan="3"><i>{{'No bridge configurations.' | translate}}</i></td>
                </tr>
                <tr *ngFor="let bridge of configurations trackById">
                    <td>
                        {{ bridge.upstreamId ? "matrix.org's bridge" : "Self-hosted bridge" }}
                        <span class="text-muted" style="display: inline-block;"
                              *ngIf="!bridge.upstreamId">({{ bridge.provisionUrl }})</span>
                    </td>
                    <td *ngIf="bridge.isOnline">
                        {{ getEnabledNetworksString(bridge) }}
                    </td>
                    <td *ngIf="!bridge.isOnline" class="error-text" colspan="2">
                        <i class="fa fa-exclamation-triangle"></i>
                        {{'This bridge is offline or unavailable.' | translate}}
                    </td>
                    <td class="text-center" *ngIf="bridge.isOnline">
                        <span class="editButton" (click)="editNetworks(bridge)">
                            <i class="fa fa-pencil-alt"></i>
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
            <button type="button" class="btn btn-success btn-sm" (click)="addModularHostedBridge()"
                    *ngIf="!hasModularBridge">
                <i class="fa fa-plus"></i> {{'Add matrix.org\'s bridge' | translate}}
            </button>
            <button type="button" class="btn btn-success btn-sm" (click)="addSelfHostedBridge()">
                <i class="fa fa-plus"></i> {{'Add self-hosted bridge' | translate}}
            </button>
        </div>
    </my-ibox>
</div>

<div *ngIf="isLoading">
    <my-spinner></my-spinner>
</div>
<div *ngIf="!isLoading">
    <my-ibox boxTitle="Widgets">
        <div class="my-ibox-content">
            <p>{{'Widgets are small webpages that can be embedded in a Matrix room. Here you can configure which widgets Dimension will offer to users.' | translate}}</p>

            <table class="table table-striped table-condensed table-bordered">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>{{'Description' | translate}}</th>
                    <th class="text-center">{{'Actions' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let widget of widgets trackById">
                    <td>{{ widget.displayName | translate }}</td>
                    <td>{{ widget.description | translate }}</td>
                    <td class="text-right">
                        <span class="editButton" (click)="editWidget(widget)"
                              *ngIf="widget.isEnabled && hasConfiguration(widget)">
                            <i class="fa fa-pencil-alt"></i>
                        </span>
                        <ui-switch [checked]="widget.isEnabled" size="small" [disabled]="isUpdating"
                                   (change)="toggleWidget(widget)"></ui-switch>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </my-ibox>
</div>

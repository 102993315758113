<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Add a new self-hosted IRC Bridge' | translate}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <p>{{'Self-hosted IRC bridges must have' | translate}}<code>{{'provisioning' | translate}}</code> {{'enabled in the configuration.' | translate}}</p>

    <label class="label-block">
        {{'Provisioning URL' | translate}}
        <span class="text-muted ">{{'The provisioning URL for the bridge. This is usually the same as the URL given in the registration. This API is not authenticated and should be treated with caution.' | translate}}</span>
        <input type="text" class="form-control"
               placeholder="http://localhost:9999"
               [(ngModel)]="provisionUrl" [disabled]="isSaving"/>
    </label>
</div>
<div class="modal-footer">
    <button type="button" (click)="add()" title="close" class="btn btn-primary btn-sm">
        <i class="far fa-save"></i> {{'Save' | translate}}
    </button>
    <button type="button" (click)="modal.close()" title="close" class="btn btn-secondary btn-sm">
        <i class="far fa-times-circle"></i> {{'Cancel' | translate}}
    </button>
</div>

<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'Embed new website' | translate}}"
        editTitle="{{'Embedded websites' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <my-field
                label="{{'Site name' | translate}}"
                [placeholder]="defaultName | translate"
                [(value)]="widget.dimension.newName"
                [disabled]="isUpdating"
        ></my-field>
        <my-field
                label="{{'Site URL' | translate}}"
                placeholder="https://matrix.org"
                [(value)]="widget.dimension.newUrl"
                [disabled]="isUpdating"
        ></my-field>
    </ng-template>
</my-widget-config>

<div *ngIf="!isCTalkAdmin()">
    <ul class="adminNav">
        <li (click)="goto('')" [ngClass]="[isActive('', true) ? 'active' : '']">{{'Dashboard' | translate}}</li>
        <li (click)="goto('widgets')" [ngClass]="[isActive('widgets') ? 'active' : '']">{{'Widgets' | translate}}</li>
        <li (click)="goto('neb')" [ngClass]="[isActive('neb') ? 'active' : '']">{{'go-neb' | translate}}</li>
        <li (click)="goto('custom-bots')" [ngClass]="[isActive('custom-bots') ? 'active' : '']">{{'Custom Bots' | translate}}</li>
        <li (click)="goto('bridges')" [ngClass]="[isActive('bridges') ? 'active' : '']">{{'Bridges' | translate}}</li>
        <li (click)="goto('stickerpacks')" [ngClass]="[isActive('stickerpacks') ? 'active' : '']">{{'Sticker Packs' | translate}}</li>
        <li (click)="goto('terms')" [ngClass]="[isActive('terms') ? 'active' : '']">{{'Terms of Service' | translate}}</li>
    </ul>
    <span class="version">{{ version }}</span>

    <div class="adminContent">
        <router-outlet></router-outlet>
    </div>
</div>

<div class="page-content" *ngIf="isCTalkAdmin()">
    <router-outlet></router-outlet>
</div>

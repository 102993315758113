<div class="field">
    <input
            *ngIf="!asSelect"
            [type]="type"
            [placeholder]="placeholder"
            [(ngModel)]="value"
            (ngModelChange)="onValueChange()"
            [id]="id"
            [disabled]="disabled"
            [maxlength]="maxlength"
    />
    <select
            *ngIf="asSelect"
            [(ngModel)]="value"
            (ngModelChange)="onValueChange()"
            [id]="id"
            [disabled]="disabled"
    >
        <option *ngFor="let opt of options trackById" [ngValue]="opt.key">{{opt.value ?? opt.key}}</option>
    </select>
    <label [htmlFor]="id">{{label}}</label>
</div>

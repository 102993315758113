<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Etherpad Widget Configuration' | translate}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <label class="label-block">
        {{'Default Pad URL Template' | translate}}
        <span class="text-muted ">{{'$padName and $roomId will be replaced during creation to help create a unique pad URL.' | translate}}</span>
        <input type="text" class="form-control"
               placeholder="https://scalar.vector.im/etherpad/p/$padName_$roomId"
               [(ngModel)]="widget.options.defaultUrl" [disabled]="isUpdating"/>
    </label>
</div>
<div class="modal-footer">
    <button type="button" (click)="save()" title="save" class="btn btn-primary btn-sm">
        <i class="far fa-save"></i> {{'Save' | translate}}
    </button>
    <button type="button" (click)="modal.close()" title="close" class="btn btn-secondary btn-sm">
        <i class="far fa-times-circle"></i> {{'Cancel' | translate}}
    </button>
</div>

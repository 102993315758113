<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Google Configuration' | translate}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body" *ngIf="isLoading">
    <my-spinner></my-spinner>
</div>
<div class="modal-body" *ngIf="!isLoading">
    <label class="label-block">
        {{'Api Key' | translate}}
        <span class="text-muted ">{{'The API key for your Google Application.' | translate}}</span>
        <input type="text" class="form-control"
               placeholder="your_api_key_here"
               [(ngModel)]="config.api_key" [disabled]="isUpdating"/>
    </label>
    <label class="label-block">
        {{'Search Engine ID' | translate}}
        <span class="text-muted ">{{'The search engine ID' | translate}}</span>
        <input type="text" class="form-control"
               placeholder="your_cx_id_here"
               [(ngModel)]="config.cx" [disabled]="isUpdating"/>
    </label>
</div>
<div class="modal-footer">
    <button type="button" (click)="save()" title="save" class="btn btn-primary btn-sm">
        <i class="far fa-save"></i> {{'Save' | translate}}
    </button>
    <button type="button" (click)="modal.close()" title="close" class="btn btn-secondary btn-sm">
        <i class="far fa-times-circle"></i> {{'Cancel' | translate}}
    </button>
</div>

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Logout confirmation' | translate}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <p>
        {{'Logging everyone out will disable all known login tokens for Dimension and upstream integration managers. Most clients will automatically re-register for a login token behind the scenes, similar to how a login token was first acquired.' | translate}}
    </p>
</div>
<div class="modal-footer">
    <button type="button" (click)="modal.dismiss()" title="close" class="btn btn-secondary btn-sm">
        <i class="far fa-times-circle"></i> {{'Cancel' | translate}}
    </button>
    <button type="button" (click)="modal.close()" title="logout everyone" class="btn btn-danger btn-sm">
        <i class="far fa-times-circle"></i> {{'Logout Everyone' | translate}}
    </button>
</div>

<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'New document' | translate}}"
        editTitle="{{'Documents' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <my-field
                label="{{'Document URL' | translate}}"
                placeholder="https://docs.google.com/document/d/1V0olL42WJ84LNYn0kFBJaPmlRxQ4Trx97a5wfVMuJC8/edit"
                [(value)]="widget.dimension.newUrl"
                [disabled]="isUpdating"
        ></my-field>
    </ng-template>
</my-widget-config>

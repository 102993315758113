<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'New whiteboard' | translate}}"
        editTitle="{{'Whiteboards' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <my-field
                label="{{'Name' | translate}}"
                placeholder="{{defaultName}}"
                [(value)]="widget.dimension.newName"
                [disabled]="isUpdating"
        ></my-field>
        <my-field
                label="{{'URL' | translate}}"
                placeholder=""
                [(value)]="widget.dimension.newUrl"
                [disabled]="isUpdating"
        ></my-field>
    </ng-template>
</my-widget-config>

<my-bridge-config [bridgeComponent]="this">
    <ng-template #bridgeParamsTemplate>
        <h3>{{'Add IRC channel' | translate}}</h3>
        <div class="alert alert-info">
            {{'Bridging a channel requires authorization from a channel operator. When entering a channel below, a bot will join the channel to ensure it exists and has operators available.' | translate}}
        </div>
        <div *ngIf="channelStep === 1">
            <my-field
                    label="{{'Network' | translate}}"
                    [asSelect]="true"
                    [(value)]="networkId"
                    [disabled]="loadingOps"
                    [selectOptions]="getNetworks()"
            ></my-field>
            <my-field
                    label="{{'Channel name (without leading #)' | translate}}"
                    placeholder="example"
                    [(value)]="channel"
                    [disabled]="loadingOps"
            ></my-field>
            <button type="button" class="element-btn" [disabled]="loadingOps"
                    (click)="loadOps()">
                {{'Next' | translate}}
            </button>
        </div>

        <div *ngIf="channelStep === 2">
            <p>{{'The person selected here will be asked to approve or deny the bridge request.' | translate}}</p>
            <my-field
                    label="{{'Operator' | translate}}"
                    [asSelect]="true"
                    [(value)]="op"
                    [disabled]="requestingBridge"
                    [selectOptions]="ops"
            ></my-field>
            <button type="button" class="element-btn" [disabled]="requestingBridge"
                    (click)="requestBridge()">
                {{'Request bridge' | translate}}
            </button>
        </div>

        <h3 style="margin-top: 36px">{{'Bridged channels' | translate}}</h3>
        <p *ngIf="getChannels().length === 0">{{'No channels are bridged to this room.' | translate}}</p>
        <details *ngFor="let channel of getChannels()">
            <summary>{{channel.name + ' on ' + channel.networkName + (channel.pending ? ' (pending)' : '')}}</summary>
            <div style="padding-bottom: 16px">
                <button type="button" class="element-btn element-btn-danger" [disabled]="isUpdating || channel.pending"
                        (click)="removeChannel(channel)">
                    {{'Unbridge channel' | translate}}
                </button>
            </div>
        </details>
    </ng-template>
</my-bridge-config>

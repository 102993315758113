<my-bridge-config [bridgeComponent]="this">
    <ng-template #bridgeParamsTemplate>
        <my-spinner *ngIf="loadingTeams"></my-spinner>
        <div *ngIf="!loadingTeams">
            <div *ngIf="isBridged && bridge.config.link.isWebhook">
                <p>{{'This room is bridged to Slack using webhooks. Webhook bridging is legacy and doesn\'t support as rich bridging as the new approach. It is recommended to re-create the bridge with the new process.' | translate}}</p>
                <button type="button" class="element-btn element-btn-danger" [disabled]="isBusy" (click)="unbridgeRoom()">
                    {{'Unbridge' | translate}}
                </button>
            </div>
            <div *ngIf="isBridged && !bridge.config.link.isWebhook">
                <p>{{'This room is bridged to ' | translate}}"{{ bridge.config.link.channelName }}"{{' on Slack.' | translate}}</p>
                <button type="button" class="element-btn element-btn-danger" [disabled]="isBusy" (click)="unbridgeRoom()">
                    {{'Unbridge' | translate}}
                </button>
            </div>
            <div *ngIf="!isBridged && needsAuth">
                <p>
                    {{'In order to bridge Slack channels, you\'ll need to authorize the bridge to access your teams and channels. Please click the button below to do so.' | translate}}
                </p>
                <a [href]="authUrl" rel="noopener" target="_blank">
                    <img src="/assets/img/slack_auth_button.png" class="slack-auth-button" alt="sign in with slack"/>
                </a>
            </div>
            <div *ngIf="!isBridged && !needsAuth">
                <my-field
                        label="{{'Team' | translate}}"
                        [asSelect]="true"
                        [(value)]="teamId"
                        [disabled]="isBusy"
                        (valueChange)="loadChannels()"
                        [selectOptions]="teamOptions"
                ></my-field>
                <my-field
                        label="{{'Channel' | translate}}"
                        [asSelect]="true"
                        [(value)]="channelId"
                        [disabled]="isBusy"
                        [selectOptions]="channelOptions"
                ></my-field>
                <button type="button" class="element-btn" [disabled]="isBusy" (click)="bridgeRoom()">
                    {{'Bridge' | translate}}
                </button>
            </div>
        </div>

    </ng-template>
</my-bridge-config>

<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'Embed music' | translate}}"
        editTitle="{{'Embedded music' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <p>{{'Click \'share\' from your favourite playlist, artist, track, or album and paste the Spotify URI here.' | translate}}</p>
        <my-field
                label="{{'Spotify URI' | translate}}"
                placeholder="spotify:artist:7CajNmpbOovFoOoasH2HaY"
                [(value)]="widget.dimension.newData.uri"
                [disabled]="isUpdating"
        ></my-field>
    </ng-template>
</my-widget-config>

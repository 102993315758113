<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'Start conference' | translate}}"
        editTitle="{{'Ongoing conferences' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <my-field
                label="{{'Conference URL' | translate}}"
                placeholder="https://jitsi.riot.im/MyCoolConference"
                [(value)]="widget.dimension.newData.conferenceUrl"
                [disabled]="isUpdating"
        ></my-field>
    </ng-template>
</my-widget-config>

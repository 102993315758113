<div class="wrapper">
    <div class="control-page" *ngIf="isLoading || authError">
        <div class="loading-badge" *ngIf="isLoading">
            <my-spinner></my-spinner>
        </div>
        <div class="auth-error" *ngIf="!isLoading && authError">
            {{'There was a problem authenticating your use of this sticker picker. Please make sure you\'re using a client that has Dimension enabled and correctly set up.' | translate}}
        </div>
    </div>
    <div class="sticker-picker" *ngIf="!isLoading && !authError">
        <div class="sticker-pack-list" (wheel)="scrollHorizontal($event)">
            <div class="sticker-pack-list-tool-bar">
                <div class="sticker-pack-list-search" *ngIf="!(!isLoading && !authError && (!packs || packs.length === 0))" (click)="isSearchSticker = true">
                    <i class="fas fa-1x fa-search"></i>
                </div>
                <div class="sticker-pack-list-config" (click)="openIntegrationManager()" *ngIf="!isSearchSticker && isDesktop">
                    <i class="fas fa-1x fa-plus-circle"></i>
                </div>
                <div class="sticker-pack-list-refresh" (click)="refresh()" *ngIf="!isSearchSticker" >
                    <i class="fa fa-1x fa-sync"></i>
                </div>
            </div>
            <div class="sticker-pack-list-search__box" *ngIf="isSearchSticker">
                <input class="input-search form-control" placeholder='Search sticker' [(ngModel)]="inputSearchSticker" (ngModelChange)="searchStickers($event)"/>
                <div class="sticker-pack-list-search__box-close-button" (click)="closeInputSearchStickers()">
                    <i class="fas fa-1x fa-times-circle"></i>
                </div>
            </div>
            <div class="sticker-pack-list-items" *ngIf="!isSearchSticker">
                <mat-tab-group
                        [disableRipple]="true"
                        [(selectedIndex)]="selectedIndex"
                >
                    <mat-tab *ngFor="let pack of (inputSearchSticker === '' ? packs : packsOfSearching); let i = index; trackById">
                        <ng-template mat-tab-label>
                            <div>
                                <img *ngIf="!pack.isAnimated" [src]="pack.animationOpts?.animationData || ''" width="30" height="30" class="image" loading="lazy"
                                     [alt]="pack.displayName" draggable="false"/>
                                <ng-lottie *ngIf="pack.isAnimated" [options]="pack.animationOpts"
                                           [styles]="{display: 'inline-block'}" width="30px" height="30px"></ng-lottie>
                            </div>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="no-stickers" *ngIf="!isLoading && !authError && (!packs || packs.length === 0)">
            <img src="/assets/img/no_stickers.png"/>
            <span class="message">{{'You have no sticker packs.' | translate}}</span>
            <button class="btn btn-link btn-sm" *ngIf="isDesktop" (click)="openIntegrationManager()">{{'Add some stickers' | translate}}</button>
        </div>
        <div class="no-stickers" *ngIf="!isLoading && !authError && (isSearchSticker && inputSearchSticker!== '' && !packsOfSearching.length)">
            <img src="/assets/img/no_stickers.png"/>
            <span class="message">{{'No stickers found.' | translate}}</span>
        </div>
        <div
             class="sticker-pack md-content"
        >
            <mat-tab-group
                    [disableRipple]="true"
                    [(selectedIndex)]="selectedIndex"
            >
                    <mat-tab *ngFor="let pack of (inputSearchSticker === '' ? packs : packsOfSearching); let i = index; trackById">
                        <ng-template mat-tab-label>
                        </ng-template>
                        <ng-template matTabContent>
                            <div class="stickers"
                                 (swipeleft)="swipe($event)"
                                 (swiperight)="swipe($event)">
                                <cdk-virtual-scroll-viewport #stickerPack itemSize="10" orientation="vertical" class="stickers-scroll">
                                    <div class="sticker" *cdkVirtualFor="let sticker of pack.stickers trackById"
                                         (click)="sendSticker(sticker, pack)">
                                        <my-spinner *ngIf="!sticker.animationOpts || !sticker.animationOpts?.animationData"></my-spinner>
                                        <img *ngIf="sticker.image.mimetype !== 'image/tgs' && sticker.animationOpts?.animationData" [src]="sticker.animationOpts?.animationData || ''" width="48" height="48" class="image" loading="lazy"
                                             [alt]="sticker.name" draggable="false"/>
                                        <ng-lottie *ngIf="sticker.image.mimetype === 'image/tgs' && sticker.animationOpts?.animationData"
                                                   [options]="sticker.animationOpts"
                                                   [styles]="{display: 'inline-block'}"
                                                   width="48px"
                                                   height="48px"
                                        ></ng-lottie>
                                    </div>

                                </cdk-virtual-scroll-viewport>
                            </div>
                        </ng-template>

                    </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
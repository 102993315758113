<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'self-hosted Telegram bridge' | translate}} ({{ isAdding ? "Add a new" : "Edit" }})</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <p>{{'Self-hosted Telegram bridges must have' | translate}} <code>{{'provisioning' | translate}}</code> {{'enabled in the configuration.' | translate}}</p>

    <label class="label-block">
        {{'Provisioning URL' | translate}}
        <span class="text-muted ">{{'The provisioning URL for the bridge. This is the public address for the bridge followed by the provisioning prefix given in the configuration.' | translate}}</span>
        <input type="text" class="form-control"
               placeholder="http://localhost:9999/_matrix/provision/v1"
               [(ngModel)]="provisionUrl" [disabled]="isSaving"/>
    </label>

    <label class="label-block">
        {{'Shared Secret' | translate}}
        <span class="text-muted ">{{'The shared secret defined in the configuration for provisioning.' | translate}}</span>
        <input type="text" class="form-control"
               placeholder="some_secret_value"
               [(ngModel)]="sharedSecret" [disabled]="isSaving"/>
    </label>

    <label class="label-block">
        {{'Promote Telegram Puppeting' | translate}}
        <span class="text-muted ">{{'If enabled, Dimension will recommend that users log in to their Telegram accounts.' | translate}}</span>
        <ui-switch [checked]="allowTgPuppets" size="small" [disabled]="isSaving"
                   (change)="allowTgPuppets = !allowTgPuppets"></ui-switch>
    </label>

    <label class="label-block">
        {{'Promote Matrix Puppeting' | translate}}
        <span class="text-muted ">{{'If enabled, Dimension will recommend that users log in to their Matrix accounts.' | translate}}</span>
        <ui-switch [checked]="allowMxPuppets" size="small" [disabled]="isSaving"
                   (change)="allowMxPuppets = !allowMxPuppets"></ui-switch>
    </label>
</div>
<div class="modal-footer">
    <button type="button" (click)="add()" title="save" class="btn btn-primary btn-sm">
        <i class="far fa-save"></i> {{'Save' | translate}}
    </button>
    <button type="button" (click)="modal.close()" title="close" class="btn btn-secondary btn-sm">
        <i class="far fa-times-circle"></i> {{'Cancel' | translate}}
    </button>
</div>

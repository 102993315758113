import { Injectable } from "@angular/core";
import {environment} from "../../../environments/environment";

// eslint-disable-next-line no-shadow
export enum ELogLevel {
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
}
@Injectable()
export class LogService {
    constructor() {}
    public debug(message: any, messageCustom: any[] = []): void {
        this.writeToLog(ELogLevel.DEBUG, message, messageCustom);
    }
    public info(message: any, messageCustom: any[] = []): void {
        this.writeToLog(ELogLevel.DEBUG, message, messageCustom);
    }
    public warn(message: any, messageCustom: any[] = []): void {
        this.writeToLog(ELogLevel.DEBUG, message, messageCustom);
    }
    public error(message: any, messageCustom: any[] = []): void {
        this.writeToLog(ELogLevel.DEBUG, message, messageCustom);
    }
    private isDevEnvironment() {
        return !environment.production;
    }
    private writeToLog(level: ELogLevel, message: any, messageCustom: any[]): void {
        if (this.isDevEnvironment()) {
            let extraInfo =  messageCustom.length ? `- Extra Info: ${this.showExtraInfo(messageCustom)}` : '';
            let logText = `[${level}]: ${new Date()} - Message: ${JSON.stringify(message)} ${extraInfo}`;
            console.log(logText);
        }
    }
    private showExtraInfo(messageCustom: any[]) {
        let extraInfo = "";
        if (messageCustom.some(messageItem => typeof messageItem === "object")) {
            for (let item of messageCustom) {
                extraInfo += JSON.stringify(item) + ",";
            }
        } else {
            extraInfo = messageCustom.join(",");
        }
        return extraInfo;
    }
}
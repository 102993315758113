<div *ngIf="isLoading">
    <my-spinner></my-spinner>
</div>
<div *ngIf="!isLoading">
    <my-ibox *ngFor="let code of chosenLanguageCodes">
        <div class="my-ibox-title">
            <h5>{{languages[code].langName}} {{'version' | translate}}</h5>
        </div>
        <div class="my-ibox-content">
            <label class="label-block">
                Name
                <span class="text-muted ">{{'The translated name of your policy' | translate}}</span>
                <input type="text" class="form-control" placeholder="My Policy" [(ngModel)]="languages[code].name"
                       [disabled]="isUpdating"/>
            </label>
            <label class="label-block">
                {{'Policy text' | translate}}
                <span class="text-muted">{{'This is where you put your policy\'s content.' | translate}}</span>
            </label>
            <ckeditor [editor]="Editor" [(ngModel)]="languages[code].text" [disabled]="isUpdating"></ckeditor>
        </div>
    </my-ibox>
    <!--    <my-ibox [hasTitle]="false">-->
    <!--        <div class="my-ibox-content buttons">-->
    <!--            <select [(ngModel)]="chosenLanguage" [disabled]="isUpdating" class="form-control form-control-sm">-->
    <!--                <option *ngFor="let lang of availableLanguages" [ngValue]="lang.code">{{lang.name}}</option>-->
    <!--            </select>-->
    <!--            <button type="button" (click)="addLanguage()" title="save" class="btn btn-outline-success btn-sm">-->
    <!--                <i class="fa fa-plus"></i> Add language-->
    <!--            </button>-->
    <!--        </div>-->
    <!--    </my-ibox>-->
    <my-ibox [hasTitle]="false">
        <div class="my-ibox-content buttons">
            <button type="button" (click)="create()" title="save" class="btn btn-primary btn-sm" *ngIf="!isEditing">
                <i class="far fa-save"></i> {{'Create draft' | translate}}
            </button>
            <button type="button" (click)="create()" title="save" class="btn btn-primary btn-sm" *ngIf="isEditing">
                <i class="far fa-save"></i> {{'Save draft' | translate}}
            </button>
            <button type="button" (click)="publish()" title="save" class="btn btn-primary btn-sm" *ngIf="isEditing">
                <i class="fas fa-upload"></i> {{'Publish' | translate}}
            </button>
        </div>
    </my-ibox>
</div>

import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {SessionStorage} from "../shared/SessionStorage";

@Component({
    selector: "my-element",
    templateUrl: "./element.component.html",
    styleUrls: ["./element.component.scss"],
})
export class ElementComponent {
    constructor(private activatedRoute: ActivatedRoute) {}

    public isAdmin(): boolean {
        return SessionStorage.isAdmin;
    }

    public isCTalkAdmin(): boolean {
        const params: any = this.activatedRoute.snapshot.queryParams;
        return params.pid === 'ctalk_admin';
    }
}

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sticker Pack: {{ pack.displayName }}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <div class="sticker" *ngFor="let sticker of pack.stickers">
        <img *ngIf="sticker.image.mimetype !== 'image/tgs'" [src]="getThumbnailUrl(sticker.thumbnail.mxc, 120, 120)" width="120" height="120"/>
        <ng-lottie *ngIf="sticker.image.mimetype === 'image/tgs'" [options]="sticker.animationOpts" [styles]="{display: 'inline-block'}" width="120px" height="120px"></ng-lottie>
        <div class="caption">
            <span class="name">{{ sticker.name }}</span>
            <span class="description">{{ sticker.description }}</span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close()"><i class="far fa-times-circle"></i> {{'Close' | translate}}</button>
</div>

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Guggy Configuration' | translate}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body" *ngIf="isLoading">
    <my-spinner></my-spinner>
</div>
<div class="modal-body" *ngIf="!isLoading">
    <label class="label-block">
        {{'Api Key' | translate}}
        <span class="text-muted ">{{'The API key for' | translate}} <a href="http://docs.guggy.com/" target="_blank">Guggy's API</a>.</span>
        <input type="text" class="form-control"
               placeholder="your_api_key_here"
               [(ngModel)]="config.api_key" [disabled]="isUpdating"/>
    </label>
</div>
<div class="modal-footer">
    <button type="button" (click)="save()" title="save" class="btn btn-primary btn-sm">
        <i class="far fa-save"></i> {{'Save' | translate}}
    </button>
    <button type="button" (click)="modal.close()" title="close" class="btn btn-secondary btn-sm">
        <i class="far fa-times-circle"></i> {{'Cancel' | translate}}
    </button>
</div>

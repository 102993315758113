import { Component, OnInit } from "@angular/core";
import { FE_UserStickerPack } from "../../shared/models/integration";
import { StickerApiService } from "../../shared/services/integrations/sticker-api.service";
import { ToasterService } from "angular2-toaster";
import { MediaService } from "../../shared/services/media.service";
import { ScalarClientApiService } from "../../shared/services/scalar/scalar-client-api.service";
import { WIDGET_STICKER_PICKER } from "../../shared/models/widget";
import { TranslateService } from "@ngx-translate/core";
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {LogService} from "../../shared/services/log.service";

@Component({
    templateUrl: "stickerpicker.component.html",
    styleUrls: ["stickerpicker.component.scss"],
})
export class StickerpickerComponent implements OnInit {

    public isLoading = true;
    public isUpdating = false;
    public packs: FE_UserStickerPack[];

    // Import stuff
    public packUrl = "";
    public isImporting = false;
    public customEnabled = false;
    public managerUrl: string;
    public stickerBot: string;
    constructor(private stickerApi: StickerApiService,
        private media: MediaService,
        private scalarClient: ScalarClientApiService,
        private toaster: ToasterService,
        private window: Window,
        public translate?: TranslateService,
        private dbService?: NgxIndexedDBService,
        private logService?: LogService
    ) {
        this.translate = translate;
        this.isLoading = true;
        this.isUpdating = false;
    }

    public async ngOnInit() {
        try {
            this.packs = await this.stickerApi.getPacks();
            for (let i = 0; i < this.packs.length; i++) {
                if (this.packs[i].isAnimated) {
                    const mxc = this.packs[i].avatarUrl;
                    this.media.getMediaContent(mxc).subscribe(data => {
                        this.packs[i].animationOpts = {
                            animationData: data
                        };
                    });
                }
            }
            this.isLoading = false;
        } catch (e) {
            console.error(e);
            this.translate.get('Failed to load sticker packs').subscribe((res: string) => {
                this.toaster.pop("error", res);
            });
        }

        this.stickerApi.getConfig().then(config => {
            this.customEnabled = config.enabled;
            this.managerUrl = config.managerUrl;
            this.stickerBot = config.stickerBot;
        }).catch(err => console.error(err));
    }

    public importPack() {
        this.isImporting = true;
        this.stickerApi.importStickerpack(this.packUrl).then(pack => {
            // Insert at top for visibility
            this.packs.splice(0, 0, pack);
            this.packUrl = "";
            this.isImporting = false;
            this.translate.get('Stickerpack added').subscribe((res: string) => {
                this.toaster.pop("success", res);
            });
            this.addWidget();
        }).catch(err => {
            console.error(err);
            this.isImporting = false;
            this.translate.get('Error adding stickerpack').subscribe((res: string) => {
                this.toaster.pop("error", res);
            });
        });
    }

    public getThumbnailUrl(mxc: string, width: number, height: number, method: "crop" | "scale" = "scale"): string {
        return this.media.getThumbnailUrl(mxc, width, height, method, true);
    }

    public toggleSelected(pack: FE_UserStickerPack) {
        pack.isSelected = !pack.isSelected;
        this.isUpdating = true;
        this.stickerApi.togglePackSelection(pack.id, pack.isSelected).then(async () => {
            this.isUpdating = false;
            // this.translate.get('Stickers updated').subscribe((res: string) => {
            //     this.toaster.pop("success", res);
            // });

            if(!pack.isSelected) {
                try {
                    await this.dbService
                        .bulkDelete('packs', [pack.id]).toPromise();
                    let stickers = await this.dbService.getAll('stickers').toPromise() || [];
                    let listIdOfSticker = stickers.filter((s: any) => {
                        return s.id.includes(`${pack.id}-`);
                    }).map((i: any) => i.id);
                    if (listIdOfSticker.length) {
                        await this.dbService
                            .bulkDelete('stickers', listIdOfSticker).toPromise();
                    }
                } catch (e) {
                    this.logService.error(e);
                }
            } else {
                const packWithoutAnimationOpts = Object.assign({}, pack);
                delete packWithoutAnimationOpts.animationOpts;
                try {
                    await this.dbService
                        .bulkAdd('packs', [packWithoutAnimationOpts])
                        .toPromise();
                } catch (e) {
                    this.logService.error(e);
                }
            }
            if (this.packs.filter(p => p.isSelected).length > 0){
                this.addWidget();
            }
        }).catch(err => {
            this.logService.error(err);
            pack.isSelected = !pack.isSelected; // revert change
            this.isUpdating = false;
            this.translate.get('Error updating stickers').subscribe((res: string) => {
                this.toaster.pop("error", res);
            });
        });
    }

    private async addWidget() {
        try {
            const widgets = await this.scalarClient.getWidgets();
            const stickerPicker = widgets.response.find(w => w.content && w.content.type === "m.stickerpicker");
            const widgetId = stickerPicker ? ((<any>stickerPicker).id || stickerPicker.state_key) : "dimension-stickerpicker-" + (new Date().getTime());

            const targetUrl = this.window.location.origin + "/widgets/stickerpicker";

            if (Array.isArray(widgets.response) && widgets.response.length && widgets.response[0].content.url === targetUrl) {
                console.warn("Not replacing Dimension sticker picker");
                return;
            }

            console.log("Force-setting new widget of ID " + widgetId);
            await this.scalarClient.setUserWidget({
                id: widgetId,
                type: WIDGET_STICKER_PICKER[0],
                url: targetUrl,
                data: {
                    dimension: {
                        wrapperId: "stickerpicker",
                    },
                },
            });
        } catch (e) {
            console.error("Failed to check for Dimension sticker picker");
            console.error(e);
        }
    }
}

<div *ngIf="isLoading">
    <my-spinner></my-spinner>
</div>
<div *ngIf="!isLoading">
    <my-ibox boxTitle="Sticker Packs">
        <div class="my-ibox-content">
            <p>
                {{'Sticker packs provide a way to convey memes or feelings to others in a room. From here you\'re able to select which sticker packs users of this Dimension instance can use. If no sticker packs are enabled then the \'sticker picker\' widget will be disabled.' | translate}}
            </p>

            <div class="input-group input-group-sm telegram-import">
                <input type="text" class="form-control" [(ngModel)]="tgUrl"
                       placeholder="https://t.me/addstickers/YourPackID"
                       [disabled]="isImporting"/>
                <span class="input-group-btn">
                    <button class="btn {{!isImporting ? 'btn-primary' : 'btn-warning'}}" (click)="startTelegramImport()" [disabled]="!tgUrl || isImporting">
                        <span *ngIf="!isImporting">
                            <i class="fa fa-download"></i>
                        </span>
                        <span *ngIf="isImporting">
                            <i class="fa fa-spinner fa-spin"></i>
                        </span>
                        {{!(tgUrl && isImporting) ? ('Import from Telegram' | translate) : ('Importing stickers, please wait…' | translate)}}
                    </button>
                </span>
            </div>

            <table class="table table-striped table-condensed table-bordered">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>{{'Description' | translate}}</th>
                    <th>{{'Author' | translate}}</th>
                    <th>{{'License' | translate}}</th>
                    <th class="text-center" style="width: 120px;">{{'Actions' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="!packs || packs.length === 0">
                    <td colspan="5"><i>{{'No sticker packs installed.' | translate}}</i></td>
                </tr>
                <tr *ngFor="let pack of packs trackById">
                    <td>{{ pack.displayName | translate}}</td>
                    <td>{{ pack.description | translate }}</td>

                    <td *ngIf="pack.author.type !== 'none'">
                        <a [href]="pack.author.reference" target="_blank">{{ pack.author.name }}</a>
                    </td>
                    <td *ngIf="pack.author.type === 'none'">{{'Dimension' | translate}}</td>

                    <td><a [href]="pack.license.urlPath" target="_blank">{{ pack.license.name }}</a></td>
                    <td class="text-center">
                        <span class="previewButton" title="preview stickers" (click)="previewStickers(pack)">
                            <i class="fa fa-eye"></i>
                        </span>
                        <ui-switch [checked]="pack.isEnabled" size="small" [disabled]="isUpdating"
                                   (change)="toggleEnabled(pack)"></ui-switch>
                        <span *ngIf="!pack.isEnabled && !isUpdating" class="removeButton" title="remove stickerpack" (click)="removePack(pack)">
                            <i class="fa fa-trash"></i>
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </my-ibox>
</div>

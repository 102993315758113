import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthedApi} from "./authed-api";
import { Observable } from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable()
export class MediaService extends AuthedApi {
    constructor(http: HttpClient) {
        super(http);
    }

    public getThumbnailUrl(mxc: string, width: number, height: number, method: "scale" | "crop" = "scale", isAnimated = true): string {
        mxc = mxc.substring("mxc://".length).split('?')[0];
        return `/api/v1/dimension/media/thumbnail/${mxc}?width=${width}&height=${height}&method=${method}&animated=${isAnimated}`;
    }

    public getMediaContent(mxc: string): Observable<string> {
        mxc = mxc.substring("mxc://".length).split('?')[0];
        const url = `/api/v1/dimension/media/download/${mxc}`;
        return this.authedGet<string>(url);
    }

    public mxcUrlToHttp (mxcUrl: string, options = {}) {
        const defaultOptions = {
            width: 24,
            height: 24,
            method: 'scale', // scale | crop
        };
        const mergedOptions = { ...defaultOptions, ...options };
        const query = new URLSearchParams();
        query.set('width', String(mergedOptions.width));
        query.set('height', String(mergedOptions.height));
        query.set('method', mergedOptions.method);
        const homeserver = environment.homeserver;
        const re = /^mxc:\/\/([^/]+)\/(\w+)/;
        const ret = re.exec(mxcUrl);
        if (ret === null) return null;
        const serverName = ret[1];
        const mediaId = ret[2];
        return `${homeserver}/_matrix/media/r0/thumbnail/${serverName}/${mediaId}?${query.toString()}`;
    };
}

import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminApiService } from "../shared/services/admin/admin-api.service";
import { SessionStorage } from "../shared/SessionStorage";

@Component({
    templateUrl: "./admin.component.html",
    styleUrls: ["./admin.component.scss"],
})
export class AdminComponent {

    public version = "";

    constructor(private router: Router, adminApi: AdminApiService, private activatedRoute: ActivatedRoute) {
        adminApi.getVersion().then(r => this.version = r.version);
        const params: any = this.activatedRoute.snapshot.queryParams;
        if (params.scalar_token) {
            SessionStorage.scalarToken = params.scalar_token;
        }
    }

    public goto(adminRoute: string) {
        const currentRoute = this.router.routerState.snapshot.url;
        const baseRoute = currentRoute.substring(0, currentRoute.indexOf("/admin"));

        const route = adminRoute ? [baseRoute, "admin", adminRoute] : [baseRoute, "admin"];
        this.router.navigate(route);
    }

    public isActive(adminRoute: string, exact?: boolean) {
        let currentRoute = this.router.routerState.snapshot.url;
        currentRoute = currentRoute.substring(currentRoute.indexOf('/admin'));

        // Specifically for the dashboard handling
        if (adminRoute === "" && !currentRoute.endsWith("/")) currentRoute = currentRoute + "/";

        if (exact) return currentRoute === "/admin/" + adminRoute;
        return currentRoute.startsWith("/admin/" + adminRoute);
    }

    public isCTalkAdmin(): boolean {
        const params: any = this.activatedRoute.snapshot.queryParams;
        return params.pid === 'ctalk_admin';
    }
}

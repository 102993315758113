<iframe *ngIf="embedUrl"
        id="bigBlueButtonContainer"
        [src]="embedUrl"
        (load)="onIframeLoad()"
        frameborder="0"
        allowfullscreen
        width="100%"
        height="100%"
        allow="camera; microphone; encrypted-media; autoplay;"
></iframe>

<div *ngIf="!embedUrl" class="join-conference-wrapper">
    <div class="join-conference-boat">
        <div *ngIf="statusMessage; else joinMeetingPrompt" class="join-conference-prompt">
            <h4 [innerHTML]="statusMessage"></h4>
        </div>
        <ng-template #joinMeetingPrompt>
            <div class="join-conference-prompt">
                <h3>{{'BigBlueButton Conference' | translate}}</h3>
                <button type="button" (click)="joinConference()" class="btn btn-primary btn-large">
                    {{'Join Conference' | translate}}
                </button>
            </div>
        </ng-template>
    </div>
</div>

<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'New meeting' | translate}}"
        editTitle="{{'Meetings' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <my-field
                label="{{'Meeting URL' | translate}}"
                placeholder="https://bbb.example.com/abc-def-ghi"
                [(value)]="widget.dimension.conferenceUrl"
                [disabled]="isUpdating"
        ></my-field>
    </ng-template>
</my-widget-config>

<div *ngIf="showMessage">
    <div class="alert alert-success" [ngClass]="alertClass">{{ alertMessage }}</div>
</div>
<div *ngIf="isLoading">
    <my-spinner></my-spinner>
</div>

<div *ngIf="!isLoading && !showMessage && type === OPEN_DIMENSION_TYPES.WIDGET">
    <!-- ------------------------ -->
    <!-- CATEGORIES               -->
    <!-- ------------------------ -->
    <div *ngFor="let category of getCategories()">
        <h3 *ngIf="getIntegrationsIn(category).length > 0">{{category}}</h3>
        <my-integration-bag
            [integrations]="getIntegrationsIn(category)"
            (integrationClicked)="modifyIntegration($event)"
        ></my-integration-bag>
    </div>
</div>
<div *ngIf="!isLoading && !showMessage && type === OPEN_DIMENSION_TYPES.BOT">
    <div class="box-action">
        <h2>Add bots</h2>
        <form (submit)="onSearchBot()" novalidate name="importForm">
            <div class="search">
                <div class="search-field-container">
                    <my-field
                        [label]="'Bot\'s share key' | translate"
                        [placeholder]="'Bot\'s share key' | translate"
                        [asSelect]="false"
                        [(value)]="key"
                        [disabled]="isLoading"
                    ></my-field>
                </div>
                <div class="button-search-bot">
                    <button
                        type="button"
                        title="save"
                        class="btn btn-primary btn-sm btn-search"
                        (click)="onSearchBot()"
                        [disabled]="!key || isLoadingAddBot"
                    >
                        <i class="fa fa-search"></i>
                        <div>
                            {{'Search' | translate}}
                        </div>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div>
        <div *ngIf="getIntegrationsIn('Bots').length === 0" class="box-data">
            <label class="label-require-search">
                {{'Enter share key to add bot' | translate}}
            </label>
        </div>
        <div *ngIf="getIntegrationsIn('Bots').length > 0">
            <div class="list-bots">
                <my-integration-bag
                        [integrations]="getIntegrationsIn('Bots')"
                        (integrationClicked)="modifyIntegration($event)"
                        [isLoading]="isLoadingAddBot"
                ></my-integration-bag>
            </div>
        </div>
    </div>
</div>

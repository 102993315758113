<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'go-neb appservice configuration' | translate}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body" *ngif="isLoading">
    <my-spinner></my-spinner>
</div>
<div class="modal-body" *ngif="!isLoading">
    {{'Copy and paste this configuration to' | translate}} <code>appservice-{{appservice.id}}.yaml</code>
    {{'on your homeserver and register it as an application service.' | translate}}
    <br/>
    <pre>{{appserviceConfig}}</pre>
</div>
<div class="modal-footer">
    <button type="button" (click)="test()" title="save" class="btn btn-primary btn-sm">
        <i class="fa fa-exchange-alt"></i> {{'Test Configuration' | translate}}
    </button>
    <button type="button" (click)="modal.close()" title="close" class="btn btn-secondary btn-sm">
        <i class="far fa-times-circle"></i> {{'Cancel' | translate}}
    </button>
</div>

<div class="header">
    <div class="title">
        <div *ngIf="!isHome" routerLink="/riot-app" class="back-btn">
            <span class="dim-icon dim-chevron-left"></span> <span>{{ 'Browse integrations' | translate }}</span>
        </div>
        <h2 class="pageName">{{ pageName | translate }}</h2>
    </div>
    <div class="quickAction">
        <ng-content></ng-content>
    </div>
</div>

<div *ngIf="isLoading">
    <my-spinner></my-spinner>
</div>
<div *ngIf="!isLoading">
    <my-ibox boxTitle="Telegram Bridge Configurations">
        <div class="my-ibox-content">
            <p>
                <a href="https://github.com/tulir/mautrix-telegram" target="_blank">{{'mautrix-telegram' | translate}}</a>
                {{'is a Telegram bridge that supports bridging channels/supergroups to Matrix. This can be done through a \'relay bot\' (all Matrix users are represented through a single bot in Telegram) or by making use of a user\'s real Telegram account (known as \'puppeting\'). Currently only one Telegram bridge can be configured at a time.' | translate}}
            <table class="table table-striped table-condensed table-bordered">
                <thead>
                <tr>
                    <th>{{'Name' | translate}}</th>
                    <th>{{'Enabled Features' | translate}}</th>
                    <th class="text-center" style="width: 120px;">{{'Actions' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="!configurations || configurations.length === 0">
                    <td colspan="3"><i>{{'No bridge configurations.' | translate}}</i></td>
                </tr>
                <tr *ngFor="let bridge of configurations trackById">
                    <td>
                        {{ bridge.upstreamId ? "matrix.org's bridge" : "Self-hosted bridge" }}
                        <span class="text-muted" style="display: inline-block;"
                              *ngIf="!bridge.upstreamId">({{ bridge.provisionUrl }})</span>
                    </td>
                    <td>
                        {{ getEnabledFeaturesString(bridge) }}
                    </td>
                    <td class="text-center">
                        <span class="editButton" (click)="editBridge(bridge)">
                            <i class="fa fa-pencil-alt"></i>
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
            <button type="button" class="btn btn-success btn-sm" (click)="addSelfHostedBridge()"
                    [disabled]="configurations && configurations.length > 0">
                <i class="fa fa-plus"></i> {{'Add self-hosted bridge' | translate}}
            </button>
        </div>
    </my-ibox>
</div>

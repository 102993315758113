<div *ngIf="showPromoPage" class="promo">
    <div class="hero">
        <img src="/assets/img/logo/banner-sm.png" class="logo">
        <h1>{{'An open source integration manager for Matrix' | translate}}</h1>
        <div class="banner">
            {{'Self-host your favourite bots, bridges, and widgets.' | translate}}
        </div>
        <div class="links">
            <a href="https://github.com/turt2live/matrix-dimension" target="_blank">{{'source' | translate}}</a>
            <a href="https://matrix.to/#/#dimension:t2bot.io">#dimension:t2bot.io</a>
        </div>
    </div>

    <div class="wrapper">
        <div class="subhero-wrapper">
            <div class="info-box try-dimension shadowed">
                <h3>{{'Try it out or' | translate}} <a href="https://github.com/turt2live/matrix-dimension#running-your-own" target="_blank">{{'run your own' | translate}}</a></h3>
                <p>
                    {{'Visit' | translate}} <a href="https://element.t2host.io" target="_blank">element.t2host.io</a>
                    {{'and log in with your Matrix account or point your Element' | translate}} <code>config.json</code> {{'at our servers:' | translate}}
                </p>
                <pre>{{ integrationsConfig }}</pre>
            </div>
            <div class="screenshot shadowed">
                <img src="/assets/img/screenshot.png">
            </div>
        </div>

        <hr class="spacer">

        <div class="integrations">
            <h3 class="title">{{'Widgets' | translate}}</h3>
            <p class="intro">{{'Add utility for everyone in your room by embedding an application.' | translate}}</p>

            <div class="integration-list">
                <div class="integration">
                    <img src="/assets/img/avatars/jitsi.png">
                    <span>Jitsi</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/etherpad.png">
                    <span>{{'Notes' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/tradingview.png">
                    <span>TradingView</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/spotify.png">
                    <span>Spotify</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/youtube.png">
                    <span>YouTube</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/twitch.png">
                    <span>Twitch Livestream</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/grafana.png">
                    <span>Grafana</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/googledocs.png">
                    <span>Google Docs</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/googlecalendar.png">
                    <span>{{'Google Calendar' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/bigbluebutton.png">
                    <span>BigBlueButton</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/whiteboard.png">
                    <span>Whiteboard</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/customwidget.png">
                    <span>{{'Custom Widget' | translate}}</span>
                </div>
            </div>
        </div>

        <hr class="spacer">

        <div class="integrations">
            <h3 class="title">Bots</h3>
            <p class="intro">{{'Bots bring entertainment or productivity to the room. They\'re here to help at your command.' | translate}}</p>

            <div class="integration-list">
                <!--<div class="integration">-->
                <!--<img src="/assets/img/avatars/github.png">-->
                <!--<span>GitHub</span>-->
                <!--</div>-->
                <!--<div class="integration">-->
                <!--<img src="/assets/img/avatars/jira.png">-->
                <!--<span>Jira</span>-->
                <!--</div>-->
                <div class="integration">
                    <img src="/assets/img/avatars/guggy.png">
                    <span>{{'Guggy' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/giphy.png">
                    <span>{{'Giphy' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/imgur.png">
                    <span>{{'Imgur' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/google.png">
                    <span>{{'Google Image Search' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="/assets(img/avatars/wikipedia.png">
                    <span>{{'Wikipedia' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/travisci.png">
                    <span>{{'Travis CI' | translate}}</span>
                </div>
                <!--<div class="integration">-->
                <!--<img src="/assets/img/avatars/circleci.png">-->
                <!--<span>Circle CI</span>-->
                <!--</div>-->
                <div class="integration">
                    <img src="/assets/img/avatars/rssbot.png">
                    <span>{{'RSS Notifications' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/echo.png">
                    <span>{{'Echo' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/custombots.png">
                    <span>{{'Custom Bots' | translate}}</span>
                </div>
            </div>
        </div>

        <hr class="spacer">

        <div class="integrations">
            <h3 class="title">Bridges</h3>
            <p class="intro">{{'Bring the outside world into your room with bridges.' | translate}}</p>

            <div class="integration-list">
                <div class="integration">
                    <img src="/assets/img/avatars/irc.png">
                    <span>IRC</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/telegram.png">
                    <span>Telegram</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/slack.png">
                    <span>Slack</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/github.png">
                    <span>GitHub</span>
                </div>
                <div class="integration">
                    <img src="/assets/img/avatars/webhooks.png">
                    <span>Webhooks</span>
                </div>
            </div>
        </div>

        <hr class="spacer">

        <div class="integrations">
            <h3 class="title">{{'Stickers' | translate}}</h3>
            <p class="intro">{{'Have some fun and post a sticker.' | translate}}</p>

            <div class="integration-list">
                <div class="integration">
                    <img src="https://t2bot.io/_matrix/media/r0/thumbnail/t2bot.io/193408b58f5e1eb72d9bea13f23914e6?width=64&height=64&method=scale&animated=true">
                    <span>{{'Huskies' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="https://t2bot.io/_matrix/media/r0/thumbnail/t2bot.io/8c88a05eb8e5a555830c8fffa36043f5?width=64&height=64&method=scale&animated=true">
                    <span>{{'Cats' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="https://t2bot.io/_matrix/media/r0/thumbnail/t2bot.io/01e06e2489185ac5b1fc73c904e1d5f0?width=64&height=64&method=scale&animated=true">
                    <span>{{'Cat Faces' | translate}}</span>
                </div>
                <div class="integration">
                    <img src="https://t2bot.io/_matrix/media/r0/thumbnail/t2bot.io/d7a7c72df5ea59c432eb142646b45a96?width=64&height=64&method=scale&animated=true">
                    <span>{{'Loading Artist' | translate}}</span>
                </div>
            </div>
        </div>

        <hr class="spacer">
    </div>

    <div class="footer">
        <a href="https://github.com/turt2live/matrix-dimension" target="_blank">{{'source on GitHub' | translate}}</a>
        <a href="https://matrix.org" target="_blank" class="made-for-matrix-anchor">
            <img src="/assets/img/logo/made-for-matrix.svg" class="made-for-matrix">
        </a>
        <a href="https://matrix.to/#/#dimension:t2bot.io">#dimension:t2bot.io</a>
    </div>
</div>

<div *ngIf="!showPromoPage" class="non-promo">
    <div class="hero">
        <img src="/assets/img/logo/banner-sm.png" class="logo">
        <div class="links">
            <a href="https://github.com/turt2live/matrix-dimension" target="_blank">{{'source' | translate}}</a>
            <a href="https://matrix.to/#/#dimension:t2bot.io">#dimension:t2bot.io</a>
        </div>
    </div>


    <div class="wrapper">
        <div class="info-box welcome shadowed">
            <h3>{{'Welcome to Dimension!' | translate}}</h3>
            <p>
                {{'Join' | translate}} <a href="https://matrix.to/#/#dimension:t2bot.io" target="_blank">#dimension:t2bot.io</a>
                {{'for news and updates. Don\'t forget to star the repository on' | translate}}
                <a href="https://github.com/turt2live/matrix-dimension" target="_blank">GitHub</a>.
            </p>
            <p>{{'Here\'s the configuration options you\'ll need to update in your Element' | translate}} <code>config.json</code>:</p>
            <pre>{{ integrationsConfig }}</pre>
            {{'The location of' | translate}} <code>config.json</code>
            {{'differs depending on whether the' | translate}}
            <a href="https://github.com/vector-im/element-web/blob/develop/docs/config.md" target="_blank">web</a>
            {{'or' | translate}}
            <a href="https://github.com/vector-im/element-desktop#user-specified-configjson" target="_blank">desktop</a>
            {{'version of Element is used.' | translate}}
            <h4>{{'Configuring integrations' | translate}}</h4>
            <p>
                {{'If everything is set up correctly, you\'ll be able to access the admin area of Dimension by clicking the 3x3 grid in the top right of any room in Element. The gear icon' | translate}}
                (<i class="fa fa-cog"></i>) {{'in the top right is where you can configure your bots, bridges, and widgets.' | translate}}
            </p>

            <h4>{{'Could not connect to integrations server error' | translate}}</h4>
            <p>
                {{'When Element cannot reach Dimension or Dimension is unable to reach your homeserver an error saying \'Could not contact integrations server\' shows up in every room. Before visiting us in' | translate}}
                <a href="https://matrix.to/#/#dimension:t2bot.io" target="_blank">#dimension:t2bot.io</a>
                {{'on Matrix, here\'s a few things to check:' | translate}}
            </p>
            <ul>
                <li><strong>{{'Verify the homeserver configuration in Dimension.' | translate}}</strong>
                    {{'The name, client/server URL, and access token all need to be valid and directed at your homeserver.' | translate}}
                </li>
                <li><strong>{{'Verify federation is enabled on your homeserver.' | translate}}</strong>
                    {{'Even in a private, or non-federated, environment federation needs to be enabled so Dimension can work correctly. Dimension should still work okay if federation on your homeserver is bound to a private interface instead of being public - just be sure to set the federation URL in your configuration.' | translate}}
                </li>
                <li><strong>{{'Verify that federation is working on your homeserver.' | translate}}</strong>
                    {{'Using tools like the' | translate}} <a href="https://matrix.org/federationtester/api/report?server_name=YOUR_SERVER_HERE.com">{{'federation tester' | translate}}</a>
                    {{', make sure that federation is working on your homeserver.' | translate}}
                </li>
            </ul>
        </div>
    </div>

    <div class="footer">
        <a href="https://github.com/turt2live/matrix-dimension" target="_blank">{{'source on GitHub' | translate}}</a>
        <a href="https://matrix.org" target="_blank" class="made-for-matrix-anchor">
            <img src="/assets/img/logo/made-for-matrix.svg" class="made-for-matrix">
        </a>
        <a href="https://matrix.to/#/#dimension:t2bot.io">#dimension:t2bot.io</a>
    </div>
</div>

<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'New dashboard' | translate}}"
        editTitle="{{'Dashboards' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <my-field
                label="{{'Trading pair' | translate}}"
                [asSelect]="true"
                [(value)]="widget.dimension.newData.pair"
                [disabled]="isUpdating"
                [selectOptions]="pairs"
        ></my-field>
        <my-field
                label="{{'Interval' | translate}}"
                [asSelect]="true"
                [(value)]="widget.dimension.newData.interval"
                [disabled]="isUpdating"
                [selectOptions]="intervals"
        ></my-field>
    </ng-template>
</my-widget-config>

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as $ from "jquery";

declare let TradingView: any;

@Component({
    selector: "my-tradingview-widget-wrapper",
    templateUrl: "tradingview.component.html",
    styleUrls: ["tradingview.component.scss"],
})
export class TradingViewWidgetWrapperComponent implements OnInit {
    private symbol: string;
    private interval: string;
    private theme: "Light" | "Dark";

    constructor(activatedRoute: ActivatedRoute) {
        const params: any = activatedRoute.snapshot.queryParams;

        this.symbol = params.pair;
        this.interval = params.interval;
        this.theme = params.theme;
    }

    public ngOnInit() {
        $.getScript("https://s3.tradingview.com/tv.js", () => {
            const widget = new TradingView.widget({
                autosize: true,
                symbol: this.symbol,
                interval: this.interval,
                timezone: "Etc/UTC",
                theme: this.theme,
                style: "1",
                locale: "en",
                toolbar_bg: "#f1f3f6",
                enable_publishing: false,
                allow_symbol_change: true,
                save_image: false,
                container_id: "tradingviewContainer",
            });
            console.log("Created widget: " + widget);
        });
    }
}

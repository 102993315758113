<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ bridge.upstreamId ? "matrix.org's" : "Self-hosted" }} IRC Bridge Networks</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <table class="table table-striped table-condensed table-bordered">
        <thead>
        <tr>
            <th>{{'Network' | translate}}</th>
            <th>{{'Enabled' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let network of networks trackById">
            <td>{{ network.name }}</td>
            <td>
                <ui-switch [checked]="network.isEnabled" size="small" [disabled]="isUpdating"
                           (change)="toggleNetwork(network)"></ui-switch>
            </td>
        </tr>
        </tbody>
    </table>
</div>
<div class="modal-footer">
    <button type="button" (click)="modal.close()" title="close" class="btn btn-outline-secondary btn-sm">
        <i class="far fa-times-circle"></i> {{'Close' | translate}}
    </button>
</div>

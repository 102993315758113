<my-bridge-config [bridgeComponent]="this">
    <ng-template #bridgeParamsTemplate>
        <div *ngIf="isBridged">
            {{'This room is bridged to on Telegram' | translate}} "{{ chatName }}" (<code>{{ chatId }}</code>) {{'on Telegram' | translate}}.
            <div *ngIf="canUnbridge">
                <button type="button" class="element-btn element-btn-danger" [disabled]="isUpdating"
                        (click)="unbridgeRoom()">
                    {{'Unbridge' | translate}}
                </button>
            </div>
            <span *ngIf="!canUnbridge">
                {{'You do not have the necessary permissions in this room to unbridge the channel.' | translate}}
            </span>
        </div>
        <div *ngIf="!isBridged">
            <p class="text-muted">{{'After inviting' | translate}} <a href="https://t.me/{{ botUsername }}" target="_blank">@{{ botUsername }}</a> {{'to your Telegram chat, run the command' | translate}} <code>/id</code> {{'in the Telegram room to get the chat ID.' | translate}}</p>

            <my-field
                    label="{{'Chat ID' | translate}}"
                    type="number"
                    [(value)]="chatId"
                    [disabled]="isUpdating"
            ></my-field>
            <button type="button" class="element-btn" [disabled]="isUpdating" (click)="bridgeRoom()">
                Bridge
            </button>
        </div>
    </ng-template>
</my-bridge-config>

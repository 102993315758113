<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'New dashboard' | translate}}"
        editTitle="{{'Dashboards' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <p>{{'To get a URL, go to Grafana and click \'share\' on a graph.' | translate}}</p>
        <my-field
                label="{{'Dashboard name' | translate}}"
                placeholder="{{ defaultName | translate }}"
                [(value)]="widget.dimension.newName"
                [disabled]="isUpdating"
        ></my-field>
        <my-field
                label="{{'Dashboard URL' | translate}}"
                placeholder="https://example.com/grafana/dashboard/db/example?orgId=1&panelId=1&fullscreen"
                [(value)]="widget.dimension.newData.url"
                [disabled]="isUpdating"
        ></my-field>
    </ng-template>
</my-widget-config>

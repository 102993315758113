<div *ngIf="widgetComponent.isLoading">
    <my-spinner></my-spinner>
</div>
<div *ngIf="!widgetComponent.isLoading">
    <h3>{{addTitle}}</h3>
    <form (submit)="widgetComponent.addWidget()" novalidate name="addForm">
        <ng-container *ngTemplateOutlet="widgetParamsTemplate;context:{widget:widgetComponent.newWidget}"></ng-container>
        <div class="element-btn-row">
            <button type="submit" class="element-btn" [disabled]="widgetComponent.isUpdating">
                {{'Add widget' | translate}}
            </button>
        </div>
    </form>

    <div *ngIf="widgetComponent.widgets.length > 0" style="margin-top: 36px">
        <h3>{{editTitle}}</h3>
        <details *ngFor="let widget of widgetComponent.widgets trackById" [open]="widget.id === widgetComponent.defaultExpandedWidgetId">
            <summary>{{widget.name || widget.url || widgetComponent.defaultName}}</summary>
            <form (submit)="widgetComponent.saveWidget(widget)" novalidate name="editForm">
                <ng-container *ngTemplateOutlet="widgetParamsTemplate;context:{widget:widget}"></ng-container>

                <div class="element-btn-row">
                    <button type="submit" class="element-btn" [disabled]="widgetComponent.isUpdating">
                        {{'Save' | translate}}
                    </button>
                    <button type="button" class="element-btn element-btn-danger-link" [disabled]="widgetComponent.isUpdating"
                            (click)="widgetComponent.removeWidget(widget)">
                        {{'Remove widget' | translate}}
                    </button>
                </div>
            </form>
        </details>
    </div>
</div>

<div class="integration-bag">
    <div class="integration"
         *ngFor="let integration of integrations"
         (click)="onClick(integration)"
         [ngClass]="integration.category === 'bot' && integration._inRoom ? 'added' : '' "
    >
        <div  *ngIf="integration.category !== 'bot'">
            <img class="integration-avatar" [src]="getSafeUrl(integration.avatarUrl)"/>
            <div class="integration-name">{{integration.displayName | translate}}</div>
            <div class="integration-description">{{ integration.description | translate }}</div>
        </div>
        <div  *ngIf="integration.category === 'bot'">
            <img class="integration-avatar border-avatar" [src]="getSafeUrl(integration.avatarUrl)"/>
            <div
                class="integration-name-bot"
                [ngbTooltip]="integration.displayName | translate"
            >
                {{integration.displayName | translate}}
            </div>
            <div
                class="integration-description-bot"
            >
                {{ integration.description | translate }}
            </div>
        </div>
        <div class="integration-offline error-text"
             *ngIf="!integration.isOnline && integration.category !== 'bot'"
        >
            <i class="fa fa-exclamation-triangle"></i>
        </div>
        <div class="bot-toggle" *ngIf="integration.category === 'bot'">
            <div class="container-spinner" *ngIf="isLoading">
                <svg class="spinner-add" viewBox="25 25 50 50">
                    <circle class="path-add" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle>
                </svg>
            </div>
            <div class="action-bot" *ngIf="!isLoading">
                <div *ngIf="!integration._inRoom">
                    <i class="fa fa-plus"></i>
                </div>
                <div *ngIf="integration._inRoom">
                    <i class="fa fa-check"></i>
                </div>
            </div>
        </div>
    </div>
</div>

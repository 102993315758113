<my-widget-config
        [widgetComponent]="this"
        addTitle="{{'New calendar' | translate}}"
        editTitle="{{'Calendars' | translate}}"
>
    <ng-template #widgetParamsTemplate let-widget="widget">
        <my-field
                label="{{'Shared calendar ID' | translate}}"
                placeholder="en.uk#holiday@group.v.calendar.google.com"
                [(value)]="widget.dimension.shareId"
                [disabled]="isUpdating"
        ></my-field>
    </ng-template>
</my-widget-config>

<div class="ibox">
    <div class="ibox-title" (click)="isCollapsible ? (collapsed = !collapsed) : false"
         [ngClass]="[isCollapsible ? 'ibox-title-collapsible' : '']"
         *ngIf="hasTitle">
        <h5 *ngIf="boxTitle">
            {{ boxTitle }}
        </h5>
        <ng-content select=".my-ibox-title" *ngIf="!boxTitle"></ng-content>
        <ng-content select=".my-ibox-addl-title"></ng-content>
        <i class="ibox-icon fa fa-chevron-down" *ngIf="isCollapsible"></i>
    </div>
    <div class="ibox-content" *ngIf="!collapsed" [@fadeInNotOutAnimation]>
        <ng-content select=".my-ibox-content"></ng-content>
    </div>
</div>
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { AuthedApi } from "../authed-api";
import { FE_Bot } from "../../models/admin-responses";
import { environment } from "../../../../environments/environment";

@Injectable()
export class AdminBotsApiService extends AuthedApi {
    private baseUrl = environment.auth_server?.base_url || '';
    constructor(http: HttpClient) {
        super(http);
    }

    /**
     * Search bot by share key
     * @param key
     */
    public searchBot(key: string): Promise<FE_Bot> {
        return this.authedGet<FE_Bot>(this.baseUrl + `/share/bot/${key}` ).toPromise();
    }
}
